import React from "react";

// Utilities
import { kebabCase } from "lodash";

// Components
import { Link, graphql } from "gatsby";
import Layout from "../components/layout.js";

const TagsPage = ({
  data: {
    allAsciidoc: { group },
  },
}) => (
  <Layout title="All tags">
    <article>
      <h1>All tags</h1>
      <ul>
        {group.map((tag) => (
          <li key={tag.fieldValue}>
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </article>
  </Layout>
);

export default TagsPage;

export const pageQuery = graphql`
  query {
    allAsciidoc(limit: 2000) {
      group(field: fields___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
